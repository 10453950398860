// extracted by mini-css-extract-plugin
export var accordion = "Help-module--accordion--02cb9";
export var android = "Help-module--android--fbfb2";
export var buy = "Help-module--buy--07ad5";
export var container = "Help-module--container--b96a6";
export var download = "Help-module--download--7fbf4";
export var header = "Help-module--header--1c786";
export var ios = "Help-module--ios--46dd4";
export var open = "Help-module--open--10275";
export var phones = "Help-module--phones--68787";
export var questions = "Help-module--questions--94edd";
export var whatsapp = "Help-module--whatsapp--8070d";